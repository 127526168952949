import React from "react";
import { Layout, Row, Col } from "antd";
import { Helmet } from "react-helmet";
import "antd/dist/antd.css";
import "../styles/global-version.scss";
import LoginBg from "../images/login-bg.png";

export default function LoginLayout(props) {
  return (
    <Layout style={{ height: "100vh", background: "white" }}>
      <Helmet title="SB Express" defer={false} />
      <Row className="sb-left-section">
        <Col xl={12} md={8} xs={24} className="login-left-aside-container">
          <div style={leftImageStyle} className="login-left-aside"></div>
        </Col>
        <Col xl={12} md={16} xs={24} className="login-right-aside-container">
          {props.children}
        </Col>
      </Row>
    </Layout>
  );
}

const leftImageStyle = {
  backgroundImage: `url(${LoginBg})`,
  height: "100vh",
  backgroundSize: "cover",
};
