import React, { useState, useEffect } from 'react'
import LoginLayout from '../components/loginlayout'
import ReactCodeInput from 'react-code-input'
import authClient from '../api/auth'
import client from '../api/client'
import HeaderLogo from '../images/header-logo.png'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import { Button, Form, Input, Row, Col, notification, Spin, Space } from 'antd'
import '../styles/global-version.scss'
import { isIOS } from 'react-device-detect'
const { Search } = Input

export default function Login() {
  const [loginFormRef] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [hasUrlParam, setHasUrlParam] = useState(false)
  useEffect(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    if (id) {
      handleSearch(id)
      setHasUrlParam(true)
    }
  }, [])

  const handleSubmit = async (values) => {
    localStorage.clear()
    setErrorMsg(null)
    setLoading(true)
    if (values.pinCode && values.username) {
      await authClient
        .auth(values.username, values.pinCode)
        .then(async (response) => {
          if (response.status === 200) {
            navigate('/')
          }
        })
        .catch((error) => {
          if (error.response.status === 400) setErrorMsg(error.response.data)
          setLoading(false)
          loginFormRef.resetFields()
          loginFormRef.setFieldsValue({
            username: values.username,
          })
          console.error({ error })
          notification.destroy()
        })
    }
  }

  const getNewPincode = async () => {
    if (loginFormRef.getFieldValue('username')) {
      setLoading(true)
      await client
        .post('/sendnewpincode', { username: loginFormRef.getFieldValue('username') })
        .then((res) => {
          if (res.status === 200) {
            notification.success({
              message: `Шинэ пин кодыг илгээлээ! Та имэйл хаяг эсвэл утасны дугаараа шалгана уу!`,
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            notification.destroy()
            notification.warning({
              message: 'Таны оруулсан утасны дугаар бүртгэлгүй байна',
              duration: 10,
            })
          }
          console.error({ err })
        })
        .then(() => setTimeout(() => setLoading(false), 500))
    } else
      notification.warning({
        message: 'Та утасны дугаар/и-мэйл хаягаа оруулна уу!',
      })
  }
  const handleSearch = (value) => {
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ')
    const val = removeExtraSpace(value)
    const minLength = 11
    const maxLength = 11
    const pattern = new RegExp(`^.{${minLength},${maxLength}}$`)
    if (pattern.test(val)) {
      navigate(`/shipmentstatus/${val}`)
    } else {
      notification.destroy()
      notification.warning({
        message: 'Таны оруулсан ачааны код буруу байна.',
        duration: 10,
      })
      const url = new URL(window.location.href)
      const id = url.searchParams.get('id')
      if (id) {
        navigate('/')
      }
    }
  }
  return (
    <LoginLayout>
      <Helmet title="Нэвтрэх | SB Express" defer={false} />

      <div className="d-flex flex-column h-100" hidden={hasUrlParam}>
        <div className="flex-grow-1">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div>
              <div className="panel sb-login" style={{ minWidth: 400, marginTop: -50 }}>
                <Spin spinning={loading} size="large">
                  <img src={HeaderLogo} alt="" style={{ marginBottom: 30 }} className="login-header-image" />
                  <h1 className="text-center">Нэвтрэх</h1>
                  <div className="flex-grow-1">
                    <Form name="portal_login" className="login-form" form={loginFormRef} onFinish={handleSubmit}>
                      <Form.Item
                        className="sb-form-item"
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: 'Утасны дугаар эсвэл и-мэйл хаягаа оруулна уу!',
                          },
                        ]}
                      >
                        <Input placeholder="Утасны дугаар/И-мэйл хаяг" className="sb-input" style={inputStyle} />
                      </Form.Item>
                      <div
                        style={{
                          fontSize: 20,
                          fontFamily: 'futura-pt, sans-serif',
                          paddingLeft: 10,
                          marginBottom: 4,
                        }}
                      >
                        Пин код*
                      </div>
                      <div className="sb-pin-container">
                        <Form.Item
                          className="sb-form-item"
                          name="pinCode"
                          rules={[
                            {
                              required: true,
                              message: 'Нэвтрэх пин кодоо оруулна уу!',
                            },
                          ]}
                        >
                          <ReactCodeInput type={isIOS ? 'tel' : 'number'} fields={6} />
                        </Form.Item>
                      </div>
                      {errorMsg && (
                        <div className="text-danger mt-3" style={{ fontSize: 16 }}>
                          *{errorMsg}
                        </div>
                      )}
                      <div className="text-end my-3">
                        <Button
                          className="btn-sb btn-sb-link mb-2 sb-font-family-bold"
                          type="link"
                          style={{
                            lineHeight: 'initial',
                            padding: 0,
                            fontWeight: 500,
                            fontSize: '16px',
                          }}
                          onClick={() => getNewPincode()}
                        >
                          Шинэ пин код авах
                        </Button>
                      </div>
                      <Row>
                        <Col span={11}>
                          <Button className="btn-sb btn-sb-outline-primary w-100 mb-2 font-weight-bold" onClick={() => navigate('/register')}>
                            Бүртгүүлэх
                          </Button>
                        </Col>
                        <Col span={11} offset={2}>
                          <Button className="btn-sb btn-sb-primary w-100 mb-2 font-weight-bold" htmlType="submit">
                            Нэвтрэх
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Spin>
              </div>
              <Space align="end">
                <Button onClick={() => navigate('/privacy-policy')} type="link" style={{ color: '#a3a3a3', padding: 0 }}>
                  Privacy Policy
                </Button>
                <Button onClick={() => navigate('/terms-of-service')} type="link" style={{ color: '#a3a3a3', padding: 0 }}>
                  Terms of Service
                </Button>
              </Space>
              <div className="mt-5">
                <Search
                  className="w-100 sb-search sb-shadow"
                  placeholder="Ачааны кодоо оруулна уу!"
                  pattern={`^.{11,11}$`}
                  required
                  onSearch={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginLayout>
  )
}

const inputStyle = {
  height: '63px',
  borderRadius: '8px',
  backgroundColor: '#f0f0f0',
}
